import { AxiosService, BaseCrudService, BookService, ConfigService, inject, StoreService } from 'booksprout-app'
import { ArcServiceInterface, FindArcFilters } from './arc.service.interface'
import {
  AddReviewDto,
  AddSiteReviewDto,
  ArcClaimDto,
  ArcClaimGetArgs,
  ArcConvertedFileDto,
  ArcDto,
  ArcEligibleGroupResultDto,
  ArcGetArgs,
  ArcGetByIdArgs,
  ArcGetEligibleArgs,
  ArcGetNotificationsArgs,
  ArcNotificationsDto,
  ArcPagedResultDto,
  ArcReviewDto,
  ArcReviewSiteDto,
  ArcReviewSites,
  ArcSite,
  ArcWebStatsDto,
  AvailableDownloadCodeDto,
  BookDto,
  bsConstants,
  CancelClaimDto,
  ClaimDownloadArgs,
  ClaimEligibilityStatusDto,
  CreateArcDto,
  CreateClaimDto,
  EligibleArcDto,
  EntityDeletionSummaryDto,
  GetActiveArcDatesArgs,
  getArcSites,
  getArcSitesFromFlags,
  GetClaimEligibilityStatusArgs,
  GroupedArcClaimResultDto,
  HasIndex,
  IdDto,
  isValidReviewLink,
  KvpDto,
  ReportPirateArgs,
  ReviewExemptionArgs,
  UnCancelClaimDto,
  UpdateArcDto,
  UsedDownloadCodeDto,
  UserDto,
  validStr,
  ArcFollowerStatsDto,
  IdArgs,
  translateDateToCurrentTimezone
} from 'booksprout'
import { gql } from '@apollo/client/core'
import { AxiosResponse } from 'axios'
import { LocalStorage } from 'quasar'

const fileDownload = require('js-file-download')

const maxEmailFileSize = 7.1 * 1024 * 1024 //10mb but when it's base64'd it'll grow by about 39%

export class ArcService extends BaseCrudService<ArcDto> implements ArcServiceInterface<ArcDto> {
  public apiControllerPath = 'arc'

  public constructor (
    @inject('ConfigService') configService: ConfigService,
    @inject('StoreService') storeService: StoreService,
    @inject('AxiosService') readonly axiosService: AxiosService,
    @inject('BookService') private readonly bookService: BookService
  ) {
    super(configService, storeService, axiosService)
  }

  public readonly arcFragments = {
    claim: gql`
      fragment ArcClaimFragment on ArcClaimDto {
        id,
        createdDate,
        status,
        statusDate,
        cancellationReason,
        cancellationType,
        downloadCode,
        reviewFlags,
        optionalReviewFlags,
        requiredReviewCount,
        state,
        selectedAudioSiteFlag,
        authorOwnsArc,
        lastReadCfi,
        dueDate,
        overdueDays,
        canFollowTeam,
        reviewerStatus,
        user {
          id
          name,
          image
        },
        review {
          id,
          rating,
          title,
          review,
          privateFeedback,
          disclaimer,
          createdDate,
          updatedDate
          sites {
            id,
            link,
            site,
            status,
            statusDate,
            statusReason,
            exempt,
            state
          }
        }
      }
    `,
    arc: gql`
      fragment ArcFragment on ArcDto {
        id,
        isDraft,
        status,
        userId,
        book {
          id,
          bookCover,
          title,
          seriesName,
          seriesNumber,
          penName,
          wordCount,
          authorPenName {
            id,
            name,
            image,
            firstName,
            averageRating,
            reviewCount,
            followers {
              id
            }
          },
          linkAmazonAsin,
          linkAmazonAsinPrint,
          linkAudibleUs,
          linkAudibleUk,
          linkBarnes,
          linkBookbub,
          linkGoodreads,
          linkGoogle,
          linkSmashwords,
          linkKobo,
          linkITunes,
          smashwordsCouponCode,
          linkITunesAudio,
          linkBarnesAudio,
          linkGoogleAudio,
          linkGoodreadsAudio,
          linkKoboAudio,
          linkBookbubAudio,
          description,
          audience,
          keywords,
          releaseDate,
          category {
            id,
            name
          },
          userId,
          epubFile,
          epubPreviewFile,
          epubFileSizeBytes,
          pdfFile,
          pdfFileSizeBytes
        },
        arcTeam {
          id,
          name,
          description,
          defaultDisclaimer,
          userIsFollowing,
          averageRating,
          reviewCount,
          arcTeamMembers {
            id,
            userId,
            status
          }
        },
        claims {
          id,
          review {
            id,
            rating,
            sites {
              id,
              status
            }
          }
        },
        stats {
          reviewCount,
          averageRating,
          delayedClaimed,
          cancelledCount,
          newReviewCount,
          newReviewActivityCount,
          nextNotification {
            statusDate,
            code
          }
        }
        specialInformation,
        arcType,
        discoverableType,
        participationType,
        copyCountType,
        copyCount,
        seriesLimitType,
        reviewFlags,
        optionalReviewFlags,
        requiredReviewCount,
        optionalReviewCount,
        liveDate,
        liveDateStr,
        reviewDays,
        stopDownloadDate,
        stopDownloadDateStr,
        neverEndingPrivate,
        usDownloadCodes,
        ukDownloadCodes,
        authorsDirectDownloadCodes,
        koboDownloadCodes,
        participationPreventionType,
        dueDate,
        dueDateStr,
        dueCutOffDate,
        dueCutOffDateStr,
        vipExclusiveAccessDays,
        importedId,
        alertFlags,
        viewCount,
        impressionCount
      }
    `
  }

  readonly arcReviewClaimFragments = {
    reviewClaim: gql`
      fragment ArcReviewClaimFragment on ArcClaimDto {
        ...ArcClaimFragment,
        arc {
          id,
          dueDate,
          dueCutOffDate,
          viewCount,
          impressionCount,
          book {
            id,
            title,
            penName,
            linkAmazonAsin,
            linkAudibleUs,
            linkAudibleUk,
            linkBarnes,
            linkBookbub,
            linkGoodreads,
            linkGoogle,
            linkSmashwords,
            linkKobo,
            linkITunes,
            smashwordsCouponCode,
            linkITunesAudio,
            linkBarnesAudio,
            linkGoogleAudio,
            linkGoodreadsAudio,
            linkKoboAudio,
            linkBookbubAudio,
            authorPenName {
              id,
              name,
              image
            },
            bookCover,
            description,
            userId,
            epubFile,
            epubPreviewFile
          },
          arcTeam {
            id,
            arcTeamMembers {
              id
              status,
              arcTeamId,
              user {
                id
              }
            }
          }
        }
      },
      ${this.arcFragments.claim}
    `
  }

  private getArcResult (arc: ArcDto | undefined): ArcDto | null {
    if (arc === void 0 || arc === null) return null

    return {
      ...arc,
      bookId: arc.book?.id,
      arcTeamId: arc.arcTeam?.id,
      book: arc.book,
      arcTeam: arc.arcTeam,
      liveDate: arc.liveDateStr
        ? translateDateToCurrentTimezone(arc.liveDateStr + '')
        : void 0,
      stopDownloadDate: arc.stopDownloadDateStr
        ? translateDateToCurrentTimezone(arc.stopDownloadDateStr + '')
        : void 0,
      dueDate: arc.dueDateStr
        ? translateDateToCurrentTimezone(arc.dueDateStr + '')
        : void 0,
      dueCutOffDate: arc.dueCutOffDateStr
        ? translateDateToCurrentTimezone(arc.dueCutOffDateStr + '')
        : void 0
    }
  }

  private getClaimResult (claim: ArcClaimDto | undefined): ArcClaimDto | undefined {
    if (claim === void 0 || claim === null) return void 0

    return {
      ...claim,
      stopDownloadDate: claim.stopDownloadDate ? translateDateToCurrentTimezone(claim.stopDownloadDate + '') : void 0,
      dueDate: translateDateToCurrentTimezone(claim.dueDate + '')
    }
  }

  public get (params?: ArcGetArgs): Promise<ArcPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetArcs (
          $needle: String,
          $activeOnly: Boolean,
          $scheduledOnly: Boolean,
          $finishedOnly: Boolean,
          $draftOnly: Boolean,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $arcType: Int,
          $withNewReviewActivityStatus: Boolean,
          $forUserId: Int
        ) {
          arcs (
            needle: $needle,
            activeOnly: $activeOnly,
            scheduledOnly: $scheduledOnly,
            finishedOnly: $finishedOnly,
            draftOnly: $draftOnly,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            arcType: $arcType,
            withNewReviewActivityStatus: $withNewReviewActivityStatus,
            forUserId: $forUserId
          ) {
            totalRows,
            items {
              ...ArcFragment
            }
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((result: ArcPagedResultDto) => {
      return {
        totalRows: result.totalRows,
        items: result.items.map(this.getArcResult)
      }
    })
  }

  public getById (id?: number, args?: ArcGetByIdArgs): Promise<ArcDto | null> {
    return this.apolloClientService.query({
      variables: {
        id,
        ...args
      },
      query: gql`
        query GetArc (
          $id: Int!,
          $genericSearch: Boolean,
          $allowOwnClaim: Boolean,
          $skipEligibleCheck: Boolean
        ) {
          arc (id: $id, genericSearch: $genericSearch, allowOwnClaim: $allowOwnClaim, skipEligibleCheck: $skipEligibleCheck) {
            ...ArcFragment
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((arcDto: any) => {
      return this.getArcResult(arcDto)
    })
  }

  public getEligibleById (id?: number, args?: ArcGetByIdArgs): Promise<EligibleArcDto> {
    return this.apolloClientService.query({
      variables: {
        id,
        ...args
      },
      query: gql`
        query GetEligibleArc (
          $id: Int!,
          $genericSearch: Boolean
        ) {
          eligibleArc (id: $id, genericSearch: $genericSearch) {
            isEligible,
            arc {
              ...ArcFragment
            }
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((eligibleArcDto: any) => {
      return {
        isEligible: eligibleArcDto.isEligible,
        arc: this.getArcResult(eligibleArcDto.arc) as ArcDto
      }
    })
  }

  public createUpdateVars (model: CreateArcDto | UpdateArcDto) {
    return {
      specialInformation: model.specialInformation,
      arcType: model.arcType,
      discoverableType: model.discoverableType,
      participationType: model.participationType,
      participationPreventionType: model.participationPreventionType,
      copyCountType: model.copyCountType,
      copyCount: model.copyCount,
      seriesLimitType: model.seriesLimitType,
      reviewFlags: model.reviewFlags,
      optionalReviewFlags: model.optionalReviewFlags,
      requiredReviewCount: model.requiredReviewCount,
      liveDate: model.liveDate,
      reviewDays: model.reviewDays,
      stopDownloadDate: model.stopDownloadDate,
      neverEndingPrivate: model.neverEndingPrivate,
      bookId: model.bookId,
      arcTeamId: model.arcTeamId,
      usDownloadCodes: model.usDownloadCodes,
      ukDownloadCodes: model.ukDownloadCodes,
      authorsDirectDownloadCodes: model.authorsDirectDownloadCodes,
      koboDownloadCodes: model.koboDownloadCodes,
      vipExclusiveAccessDays: model.vipExclusiveAccessDays,
      dueDate: model.dueDate,
      alertFlags: model.alertFlags,
      liveDateStr: model.liveDateStr,
      stopDownloadDateStr: model.stopDownloadDateStr,
      dueDateStr: model.dueDateStr
    }
  }

  public create (model: CreateArcDto): Promise<ArcDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...this.createUpdateVars(model)
      },
      mutation: gql`
        mutation CreateArc (
          $specialInformation: String,
          $arcType: Int,
          $discoverableType: Int,
          $participationType: Int,
          $participationPreventionType: Int,
          $copyCountType: Int,
          $copyCount: Int,
          $seriesLimitType: Int,
          $reviewFlags: Int,
          $optionalReviewFlags: Int,
          $requiredReviewCount: Int,
          $liveDate: DateTime,
          $reviewDays: Int,
          $stopDownloadDate: DateTime,
          $neverEndingPrivate: Boolean,
          $bookId: Int!,
          $arcTeamId: Int,
          $usDownloadCodes: String,
          $ukDownloadCodes: String,
          $authorsDirectDownloadCodes: String,
          $koboDownloadCodes: String,
          $vipExclusiveAccessDays: Int,
          $dueDate: DateTime,
          $alertFlags: Int,
          $liveDateStr: String,
          $stopDownloadDateStr: String,
          $dueDateStr: String
        ) {
          createArc (
            specialInformation: $specialInformation,
            arcType: $arcType,
            discoverableType: $discoverableType,
            participationType: $participationType,
            participationPreventionType: $participationPreventionType,
            copyCountType: $copyCountType,
            copyCount: $copyCount,
            seriesLimitType: $seriesLimitType,
            reviewFlags: $reviewFlags,
            optionalReviewFlags: $optionalReviewFlags,
            requiredReviewCount: $requiredReviewCount,
            liveDate: $liveDate,
            reviewDays: $reviewDays,
            stopDownloadDate: $stopDownloadDate,
            neverEndingPrivate: $neverEndingPrivate,
            bookId: $bookId,
            arcTeamId: $arcTeamId,
            usDownloadCodes: $usDownloadCodes,
            ukDownloadCodes: $ukDownloadCodes,
            authorsDirectDownloadCodes: $authorsDirectDownloadCodes,
            koboDownloadCodes: $koboDownloadCodes,
            vipExclusiveAccessDays: $vipExclusiveAccessDays,
            dueDate: $dueDate,
            alertFlags: $alertFlags,
            liveDateStr: $liveDateStr,
            stopDownloadDateStr: $stopDownloadDateStr,
            dueDateStr: $dueDateStr
          ) {
            ...ArcFragment
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((arc: any) => this.getArcResult(arc) || new ArcDto())
  }

  public update (model: UpdateArcDto): Promise<ArcDto> {
    return this.apolloClientService.mutate({
      variables: {
        id: model.id,
        ...this.createUpdateVars(model)
      },
      mutation: gql`
        mutation UpdateArc (
          $id: Int!,
          $specialInformation: String,
          $arcType: Int,
          $discoverableType: Int,
          $participationType: Int,
          $participationPreventionType: Int,
          $copyCountType: Int,
          $copyCount: Int,
          $seriesLimitType: Int,
          $reviewFlags: Int,
          $optionalReviewFlags: Int,
          $requiredReviewCount: Int,
          $liveDate: DateTime,
          $reviewDays: Int,
          $stopDownloadDate: DateTime,
          $neverEndingPrivate: Boolean,
          $bookId: Int!,
          $arcTeamId: Int,
          $usDownloadCodes: String,
          $ukDownloadCodes: String,
          $authorsDirectDownloadCodes: String,
          $koboDownloadCodes: String,
          $vipExclusiveAccessDays: Int,
          $dueDate: DateTime,
          $alertFlags: Int,
          $liveDateStr: String,
          $stopDownloadDateStr: String,
          $dueDateStr: String
        ) {
          updateArc (
            id: $id,
            specialInformation: $specialInformation,
            arcType: $arcType,
            discoverableType: $discoverableType,
            participationType: $participationType,
            participationPreventionType: $participationPreventionType,
            copyCountType: $copyCountType,
            copyCount: $copyCount,
            seriesLimitType: $seriesLimitType,
            reviewFlags: $reviewFlags,
            optionalReviewFlags: $optionalReviewFlags,
            requiredReviewCount: $requiredReviewCount,
            liveDate: $liveDate,
            reviewDays: $reviewDays,
            stopDownloadDate: $stopDownloadDate,
            neverEndingPrivate: $neverEndingPrivate,
            bookId: $bookId,
            arcTeamId: $arcTeamId,
            usDownloadCodes: $usDownloadCodes,
            ukDownloadCodes: $ukDownloadCodes,
            authorsDirectDownloadCodes: $authorsDirectDownloadCodes,
            koboDownloadCodes: $koboDownloadCodes,
            vipExclusiveAccessDays: $vipExclusiveAccessDays,
            dueDate: $dueDate,
            alertFlags: $alertFlags,
            liveDateStr: $liveDateStr,
            stopDownloadDateStr: $stopDownloadDateStr,
            dueDateStr: $dueDateStr
          ) {
            ...ArcFragment
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((arc: any) => this.getArcResult(arc) || new ArcDto())
  }

  public checkDelete (id: number): Promise<EntityDeletionSummaryDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query CheckDeleteArc ($id: Int!) {
          checkDeleteArc (id: $id) {
            count,
            dtoTypeName,
            ids
          }
        }
      `
    })
  }

  public delete (id?: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation ConfirmDeleteArc (
          $id: Int!
        ) {
          confirmDeleteArc(id: $id) {
            id
          }
        }
      `
    })
  }

  public reportFileFactory (files: any[], args: ReportPirateArgs): object {
    return {
      url: this.getApiControllerUrl('report-pirate'),
      withCredentials: true,
      headers: [
        { name: 'Authorization', value: `Bearer ${this.storeService.store.getters.authToken}` },
        { name: 'CSRF-Token', value: this.storeService.store.getters.csrfToken }
      ],
      formFields: [
        // ReportPirateArgs shape
        { name: 'bookId', value: args.bookId },
        { name: 'fileName', value: files[0].name },
        { name: 'link', value: args.link }
      ]
    }
  }

  updateClaimReadLocation (claim: ArcClaimDto, cfi: string): Promise<ArcClaimDto> {
    return this.apolloClientService.mutate({
      variables: {
        userId: claim.user?.id,
        claimId: claim.id,
        cfi
      },
      mutation: gql`
        mutation UpdateClaimReadLocation (
          $userId: Int!
          $claimId: Int!
          $cfi: String!
        ) {
          updateClaimReadLocation(
            userId: $userId,
            claimId: $claimId,
            cfi: $cfi
          ) {
            ...ArcClaimFragment
          }
        },
        ${this.arcFragments.claim}
      `
    })
  }

  endImmediately (args: IdArgs): Promise<ArcDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation EndImmediately (
          $id: Int!
        ) {
          endImmediately (
            id: $id
          ) {
            ...ArcFragment
          }
        },
        ${this.arcFragments.arc}
      `
    })
  }

  getNotifications (args: ArcGetNotificationsArgs): Promise<ArcNotificationsDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetArcNotifications (
          $id: Int!
          $notificationCodes: [String!]!
        ) {
          arcNotifications (
            id: $id
            notificationCodes: $notificationCodes
          ) {
            notification {
              id,
              active,
              code,
              lastQueuedDate,
              templateId,
              typeFlags,
              status,
              statusDate,
              userAwaitingNotificationCount,
              userNotificationCount
            },
            sentCount,
            totalCount
          }
        }
      `
    })
  }

  getReviews (id: number): Promise<ArcReviewDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query GetArcReviews (
          $id: Int!
        ) {
          arcReviews (id: $id) {
            id,
            claim {
              id,
              userId,
              status,
              canFollowTeam,
              arc {
                id,
                book {
                  title
                }
              },
              user {
                id,
                name,
                image,
              }
            },
            createdDate,
            disclaimer,
            privateFeedback,
            title,
            review,
            rating,
            sites {
              id,
              link,
              site,
              status,
              statusDate,
              statusReason,
              exempt
            },
            userId
          }
        }
      `
    })
  }

  getClaim (arcId: number, userId: number): Promise<ArcClaimDto | undefined> {
    return this.apolloClientService.query<ArcClaimDto>({
      variables: {
        arcId,
        userId
      },
      query: gql`
        query GetArcClaim (
          $arcId: Int,
          $userId: Int
        ) {
          arcUserClaim (arcId: $arcId, userId: $userId) {
            ...ArcClaimFragment,
            arc {
              ...ArcFragment
            }
          }
        },
        ${this.arcFragments.claim},
        ${this.arcFragments.arc}
      `
    }).then(claim => {
      return this.getClaimResult(claim)
    })
  }

  getClaimToReview (arcId: number, userId: number): Promise<ArcClaimDto> {
    return this.apolloClientService.query<ArcClaimDto>({
      variables: {
        arcId,
        userId
      },
      query: gql`
        query GetArcClaim (
          $arcId: Int,
          $userId: Int
        ) {
          arcUserClaim (arcId: $arcId, userId: $userId) {
            id,
            createdDate,
            status,
            statusDate,
            cancellationReason,
            cancellationType,
            downloadCode,
            reviewFlags,
            optionalReviewFlags,
            requiredReviewCount,
            selectedAudioSiteFlag,
            canFollowTeam,
            dueDate,
            review {
              id,
              rating,
              title,
              review,
              privateFeedback,
              disclaimer,
              createdDate,
              updatedDate
              sites {
                id,
                link,
                site,
                status,
                statusDate,
                statusReason,
                exempt
              }
            },
            arc {
              id,
              isDraft,
              status,
              book {
                id,
                bookCover,
                title,
                seriesName,
                seriesNumber,
                penName,
                wordCount,
                authorPenName {
                  id,
                  name,
                  image,
                  firstName,
                  averageRating,
                  followers {
                    id
                  }
                },
                linkAmazonAsin,
                linkAmazonAsinPrint,
                linkAudibleUs,
                linkAudibleUk,
                linkBarnes,
                linkBookbub,
                linkGoodreads,
                linkGoogle,
                linkSmashwords,
                linkKobo,
                linkITunes,
                smashwordsCouponCode,
                linkITunesAudio,
                linkBarnesAudio,
                linkGoogleAudio,
                linkGoodreadsAudio,
                linkKoboAudio,
                linkBookbubAudio,
                description,
                audience,
                keywords,
                releaseDate,
                category {
                  id,
                  name
                },
                pdfFile,
                pdfFileSizeBytes,
                pdfSystemGenerated,
                epubFile,
                epubFileSizeBytes,
                epubPreviewFile,
                epubSystemGenerated
              },
              arcTeam {
                id,
                name,
                defaultDisclaimer
              }
              specialInformation,
              arcType,
              discoverableType,
              participationType,
              copyCountType,
              copyCount,
              seriesLimitType,
              reviewFlags,
              optionalReviewFlags,
              requiredReviewCount,
              optionalReviewCount,
              liveDate,
              reviewDays,
              stopDownloadDate,
              neverEndingPrivate,
              usDownloadCodes,
              ukDownloadCodes,
              authorsDirectDownloadCodes,
              koboDownloadCodes,
              participationPreventionType,
              dueDate,
              dueCutOffDate,
              vipExclusiveAccessDays
            }
          }
        }
      `
    }).then(claim => {
      return this.getClaimResult(claim) as ArcClaimDto
    })
  }

  getClaims (args: ArcClaimGetArgs): Promise<ArcClaimDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetArcClaims (
          $arcId: Int,
          $status: Int,
          $userSearchNeedle: String,
          $type: Int,
          $forAuthenticatedUser: Boolean,
          $reviewSiteStatus: Int,
          $mustHaveReviews: Boolean,
          $claimId: Int,
          $userId: Int,
          $statuses: [Int!],
          $isDue: Boolean,
          $sortBy: String,
          $genericSearchTerm: String,
          $skip: Int,
          $take: Int,
          $bookId: Int,
          $authorPenNameId: Int
        ) {
          arcClaims (
            arcId: $arcId,
            status: $status,
            userSearchNeedle: $userSearchNeedle,
            type: $type,
            forAuthenticatedUser: $forAuthenticatedUser,
            reviewSiteStatus: $reviewSiteStatus,
            mustHaveReviews: $mustHaveReviews,
            claimId: $claimId,
            userId: $userId,
            statuses: $statuses,
            isDue: $isDue,
            sortBy: $sortBy,
            genericSearchTerm: $genericSearchTerm,
            skip: $skip,
            take: $take,
            bookId: $bookId,
            authorPenNameId: $authorPenNameId
          ) {
            ...ArcReviewClaimFragment
          }
        },
        ${this.arcReviewClaimFragments.reviewClaim}
      `
    }).then((r: any) => {
      return r.map((m: ArcClaimDto) => this.getClaimResult(m))
    })
  }

  getGroupedClaimsCount (args: ArcClaimGetArgs): Promise<GroupedArcClaimResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetGroupedArcClaimsCount (
          $arcId: Int,
          $status: Int,
          $userSearchNeedle: String,
          $type: Int,
          $forAuthenticatedUser: Boolean,
          $reviewSiteStatus: Int,
          $mustHaveReviews: Boolean,
          $claimId: Int,
          $userId: Int,
          $groupByStatus: Int,
          $genericSearchTerm: String,
          $wordCounts: [Int!],
          $audienceTypes: [Int!],
          $categoryId: Int,
          $sortBy: String,
          $skip: Int,
          $take: Int,
          $countOnly: String
        ) {
          groupedArcClaimsCount (
            arcId: $arcId,
            status: $status,
            userSearchNeedle: $userSearchNeedle,
            type: $type,
            forAuthenticatedUser: $forAuthenticatedUser,
            reviewSiteStatus: $reviewSiteStatus,
            mustHaveReviews: $mustHaveReviews,
            claimId: $claimId,
            userId: $userId,
            groupByStatus: $groupByStatus,
            genericSearchTerm: $genericSearchTerm,
            categoryId: $categoryId,
            wordCounts: $wordCounts,
            audienceTypes: $audienceTypes,
            sortBy: $sortBy,
            skip: $skip,
            take: $take,
            countOnly: $countOnly
          ) {
            activeCount,
            cancelledCount,
            completeCount,
            delayedCount,
            dueCount,
            pendingPublishingCount,
          }
        }
      `
    })
  }

  getGroupedClaims (args: ArcClaimGetArgs): Promise<GroupedArcClaimResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetGroupedArcClaims (
          $arcId: Int,
          $status: Int,
          $userSearchNeedle: String,
          $type: Int,
          $forAuthenticatedUser: Boolean,
          $reviewSiteStatus: Int,
          $mustHaveReviews: Boolean,
          $claimId: Int,
          $userId: Int,
          $groupByStatus: Int,
          $genericSearchTerm: String,
          $wordCounts: [Int!],
          $audienceTypes: [Int!],
          $categoryId: Int,
          $sortBy: String,
          $skip: Int,
          $take: Int
        ) {
          groupedArcClaims (
            arcId: $arcId,
            status: $status,
            userSearchNeedle: $userSearchNeedle,
            type: $type,
            forAuthenticatedUser: $forAuthenticatedUser,
            reviewSiteStatus: $reviewSiteStatus,
            mustHaveReviews: $mustHaveReviews,
            claimId: $claimId,
            userId: $userId,
            groupByStatus: $groupByStatus,
            genericSearchTerm: $genericSearchTerm,
            categoryId: $categoryId,
            wordCounts: $wordCounts,
            audienceTypes: $audienceTypes,
            sortBy: $sortBy,
            skip: $skip,
            take: $take
          ) {
            activeCount,
            cancelledCount,
            completeCount,
            delayedCount,
            dueCount,
            pendingPublishingCount,
            result {
              totalRows,
              items {
                ...ArcClaimFragment,
                arc {
                  id,
                  dueDate,
                  dueCutOffDate,
                  arcType,
                  book {
                    id,
                    title,
                    penName,
                    authorPenName {
                      id,
                      name,
                      image,
                      followers {
                        id
                      }
                    },
                    bookCover,
                    description,
                    keywords,
                    epubFile,
                    epubPreviewFile,
                    pdfFile
                  },
                  arcTeam {
                    id,
                    arcTeamMembers {
                      id
                      status,
                      arcTeamId,
                      user {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        },
        ${this.arcFragments.claim}
      `
    }).then((result: any) => {
      return {
        ...result,
        result: {
          totalRows: result.result.totalRows,
          items: result.result.items.map((m: ArcClaimDto) => {
            return {
              ...this.getClaimResult(m),
              arc: this.getArcResult(m.arc)
            }
          })
        }
      }
    })
  }

  public grantReviewExemption (args: ReviewExemptionArgs): Promise<ArcReviewSiteDto[]> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation GrantReviewExemption (
          $claimId: Int!,
          $grantSites: [Int!]
        ) {
          grantReviewExemption (claimId: $claimId, grantSites: $grantSites) {
            id
          }
        }
      `
    })
  }

  public revokeReviewExemption (args: ReviewExemptionArgs): Promise<ArcReviewSiteDto[]> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation RevokeReviewExemption (
          $claimId: Int!,
          $revokeSites: [Int!]
        ) {
          revokeReviewExemption (claimId: $claimId, revokeSites: $revokeSites) {
            id
          }
        }
      `
    })
  }

  public reportPirate (args: ReportPirateArgs, file: File): Promise<any> {
    // Construct our form data
    const bodyFormData = new FormData()
    bodyFormData.append('bookId', args.bookId.toString())
    bodyFormData.append('fileName', file.name)
    bodyFormData.append('link', args.link)
    bodyFormData.append('uploadFile', file)

    // Post it to the AuthController on the server
    return this.axiosService.axios({
      method: 'post',
      url: this.getApiControllerUrl('report-pirate'),
      headers: {
        Authorization: `Bearer ${this.storeService.store.getters.authToken}`,
        'Content-Type': 'multipart/form-data',
        'CSRF-Token': this.storeService.store.getters.csrfToken
      },
      data: bodyFormData
    })
  }

  public getReportedPirateCount (): Promise<number> {
    return this.apolloClientService.query({
      query: gql`
        query GetReportedPirateCount {
          reportedPirateCount
        }
      `
    }).then((r: any) => {
      return r
    })
  }

  public addReview (args: AddReviewDto): Promise<ArcReviewDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation AddReview (
          $claimId: Int!,
          $disclaimer: String!,
          $privateFeedback: String!,
          $rating: Int!,
          $review: String!,
          $userId: Int!,
          $title: String!
        ) {
          addReview (
            claimId: $claimId,
            disclaimer: $disclaimer,
            privateFeedback: $privateFeedback,
            rating: $rating,
            review: $review,
            userId: $userId,
            title: $title
          ) {
            id
          }
        }
      `
    })
  }

  deleteReview (reviewId: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id: reviewId
      },
      mutation: gql`
        mutation DeleteReview (
          $id: Int!
        ) {
          deleteReview (id: $id)
        }
      `
    })
  }

  public addSiteReview (args: AddSiteReviewDto): Promise<IdDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation AddSiteReview (
          $link: String,
          $reviewId: Int!,
          $site: Int,
          $status: Int,
          $userId: Int!,
          $statusReason: String
        ) {
          addSiteReview (
            link: $link,
            reviewId: $reviewId,
            site: $site,
            status: $status,
            userId: $userId,
            statusReason: $statusReason
          ) {
            id
          }
        }
      `
    })
  }

  public claimArc (args: CreateClaimDto): Promise<ArcClaimDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...args
      },
      mutation: gql`
        mutation ClaimArc (
          $arcId: Int!,
          $userId: Int!,
          $selectedAudioSiteFlag: Int
        ) {
          claimArc (
            arcId: $arcId,
            userId: $userId,
            selectedAudioSiteFlag: $selectedAudioSiteFlag
          ) {
            id
          }
        }
      `
    })
  }

  getArcSitesFromFlags (flags: number, excludeFlags?: number): ArcSite[] {
    return getArcSitesFromFlags(flags, excludeFlags)
  }

  getArcSitesFromFlagsExcludingCountrySpecificAudible (flags: number): ArcSite[] {
    const arcSites = this.getArcSitesFromFlags(flags)
    const { AUDIBLE_US, AUDIBLE_UK } = bsConstants.ARCS.REVIEWS.FLAGS

    return arcSites.filter(flag => {
      return ![AUDIBLE_US, AUDIBLE_UK].includes(flag.site)
    })
  }

  public getArcSites (forceAdd?: boolean, requiredCallback?: (fieldName: string, site: number) => boolean): ArcReviewSites {
    return getArcSites(forceAdd, requiredCallback)
  }

  getEligibleGroupedCount (params?: ArcGetEligibleArgs): Promise<ArcEligibleGroupResultDto> {
    return this.apolloClientService.query<ArcEligibleGroupResultDto>({
      variables: {
        ...params
      },
      query: gql`
        query GetGroupedEligibleArcsCount (
          $needle: String,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $penNameId: Int
          $hasComeViaLink: Boolean,
          $arcTeamId: Int,
          $activeOnly: Boolean,
          $pastOnly: Boolean,
          $arcType: Int,
          $sites: Int,
          $lengths: [Int!],
          $audiences: [Int!],
          $canReviewInDays: Int,
          $categoryId: Int,
          $groupByStatus: Int,
          $countOnly: Boolean
        ) {
          groupedEligibleArcsCount (
            needle: $needle,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            penNameId: $penNameId,
            hasComeViaLink: $hasComeViaLink,
            arcTeamId: $arcTeamId,
            activeOnly: $activeOnly,
            pastOnly: $pastOnly,
            arcType: $arcType,
            sites: $sites,
            lengths: $lengths,
            audiences: $audiences,
            canReviewInDays: $canReviewInDays,
            categoryId: $categoryId,
            groupByStatus: $groupByStatus,
            countOnly: $countOnly
          ) {
            exclusiveCount,
            newUnreleasedCount,
            authorTeamCount,
            pastCount,
            allCount
          }
        }
      `
    })
  }

  getEligibleGrouped (params?: ArcGetEligibleArgs): Promise<ArcEligibleGroupResultDto> {
    return this.apolloClientService.query<ArcEligibleGroupResultDto>({
      variables: {
        ...params
      },
      query: gql`
        query GetGroupedEligibleArcs (
          $needle: String,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $penNameId: Int
          $hasComeViaLink: Boolean,
          $arcTeamId: Int,
          $activeOnly: Boolean,
          $pastOnly: Boolean,
          $arcType: Int,
          $sites: Int,
          $lengths: [Int!],
          $audiences: [Int!],
          $canReviewInDays: Int,
          $categoryId: Int,
          $groupByStatus: Int
        ) {
          groupedEligibleArcs (
            needle: $needle,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            penNameId: $penNameId,
            hasComeViaLink: $hasComeViaLink,
            arcTeamId: $arcTeamId,
            activeOnly: $activeOnly,
            pastOnly: $pastOnly,
            arcType: $arcType,
            sites: $sites,
            lengths: $lengths,
            audiences: $audiences,
            canReviewInDays: $canReviewInDays,
            categoryId: $categoryId,
            groupByStatus: $groupByStatus
          ) {
            exclusiveCount,
            newUnreleasedCount,
            allCount,
            authorTeamCount,
            pastCount,
            result {
              totalRows,
              items {
                ...ArcFragment
              }
            }
          }
        }
        ${this.arcFragments.arc}
      `
    }).then(result => {
      return {
        ...result,
        result: {
          totalRows: result.result.totalRows,
          items: result.result.items.map(this.getArcResult)
        }
      }
    })
  }

  public getEligible (params?: ArcGetEligibleArgs): Promise<ArcPagedResultDto> {
    return this.apolloClientService.query({
      variables: {
        ...params
      },
      query: gql`
        query GetEligibleArcs (
          $needle: String,
          $skip: Int,
          $take: Int,
          $sortBy: String,
          $descending: Boolean,
          $penNameId: Int
          $hasComeViaLink: Boolean,
          $arcTeamId: Int,
          $activeOnly: Boolean,
          $pastOnly: Boolean,
          $arcType: Int,
          $sites: Int,
          $lengths: [Int!],
          $audiences: [Int!],
          $canReviewInDays: Int,
          $categoryId: Int,
          $allUserOwned: Boolean,
          $excludeArcId: Int,
          $allowOwnClaim: Boolean,
          $penName: String
        ) {
          eligibleArcs (
            needle: $needle,
            skip: $skip,
            take: $take,
            sortBy: $sortBy,
            descending: $descending,
            penNameId: $penNameId,
            hasComeViaLink: $hasComeViaLink,
            arcTeamId: $arcTeamId,
            activeOnly: $activeOnly,
            pastOnly: $pastOnly,
            arcType: $arcType,
            sites: $sites,
            lengths: $lengths,
            audiences: $audiences,
            canReviewInDays: $canReviewInDays,
            categoryId: $categoryId,
            allUserOwned: $allUserOwned,
            excludeArcId: $excludeArcId,
            allowOwnClaim: $allowOwnClaim,
            penName: $penName
          ) {
            totalRows,
            items {
              ...ArcFragment
            }
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((result: ArcPagedResultDto) => {
      return {
        totalRows: result.totalRows,
        items: result.items.map(this.getArcResult)
      }
    })
  }

  resetFindArcFilters (): FindArcFilters {
    return {
      selectedArcType: -1,
      sites: bsConstants.ARCS.REVIEWS.ALL_FLAGS,
      reviewInDays: 0,
      wordCounts: [],
      audienceTypes: [],
      searchValue: '',
      categoryId: -1,
      advancedFilterCall: false,
      appliedCount: 0,
      sortBy: 0,
      activeFiltersString: '',
      currentPage: 1
    }
  }

  getFindArcFilterCount (currentFilters: FindArcFilters & HasIndex): number {
    const defaults: FindArcFilters & HasIndex = this.resetFindArcFilters()
    let appliedCount = 0

    // Loop tha active filters
    for (const key in currentFilters) {
      // Exclude some props we don't care about
      if (currentFilters.hasOwnProperty(key) && !['advancedFilterCall', 'appliedCount'].includes(key)) {
        // Check if the value matches (filter not set)
        let valueMatches = currentFilters[key] === defaults[key]
        // Or in the case of an array, that the lengths are the same
        if (Array.isArray(currentFilters[key])) {
          valueMatches = currentFilters[key].length === defaults[key].length
        }

        // If some values don't match, increment the counter.
        if (!valueMatches) {
          appliedCount++
        }
      }
    }

    return appliedCount
  }

  shouldShowExplicitTag (arc: ArcDto): boolean {
    return arc.book?.audience === bsConstants.BOOKS.AUDIENCE_TYPE.EXPLICIT ||
      arc.book?.audience === bsConstants.BOOKS.AUDIENCE_TYPE.MATURE
  }

  downloadClaim (args: ClaimDownloadArgs): Promise<any> {
    return this.axiosService.axios.post<ArcConvertedFileDto>('arc/download-claim', args, {
      responseType: 'blob',
      timeout: 600000 // 10 mins.
    }).then((r: AxiosResponse) => {
      const fileName = r.headers['content-disposition']?.split('filename=')[1]
      fileDownload(r.data, fileName)
      return
    })
  }

  emailClaim (args: ClaimDownloadArgs): Promise<any> {
    return this.axiosService.axios.post('arc/email-claim', args).then((r: AxiosResponse & { data: ArcConvertedFileDto }) => {
      return
    })
  }

  sizeAllowsEmail (arc: ArcDto): boolean {
    return this.canEmail(bsConstants.ARCS.CLAIMS.FILE_TYPE.EPUB, arc) ||
      this.canEmail(bsConstants.ARCS.CLAIMS.FILE_TYPE.PDF, arc)
  }

  canEmail (fileType: number, arc: ArcDto): boolean {
    let size: number | undefined = void 0
    switch (fileType) {
      case bsConstants.ARCS.CLAIMS.FILE_TYPE.EPUB:
        size = arc.book?.epubFileSizeBytes
        break
      case bsConstants.ARCS.CLAIMS.FILE_TYPE.PDF:
        size = arc.book?.pdfFileSizeBytes
        break
    }

    return !!size && size < maxEmailFileSize
  }

  isValidReviewLink (site: number, url: string): boolean {
    return isValidReviewLink(site, url)
  }

  countSiteFlags (flags: number | undefined): number {
    if (!flags) return 0
    return flags.toString(2).replace(/0/g, '').length
  }

  getOptionalReviewCount (arc: ArcDto): number {
    const requiredSiteCount = this.countSiteFlags(arc.reviewFlags)
    return arc.requiredReviewCount - requiredSiteCount
  }

  cancelClaim (dto: CancelClaimDto): Promise<IdDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...dto
      },
      mutation: gql`
        mutation CancelClaim (
          $additionalInformation: String,
          $claimId: Int!,
          $reason: String,
          $type: Int!,
          $userId: Int!
        ) {
          cancelArcClaim (
            userId: $userId,
            additionalInformation: $additionalInformation,
            claimId: $claimId,
            reason: $reason,
            type: $type
          ) {
            id
          }
        }
      `
    })
  }

  unCancelClaim (dto: UnCancelClaimDto): Promise<IdDto> {
    return this.apolloClientService.mutate({
      variables: {
        ...dto
      },
      mutation: gql`
        mutation UnCancelClaim (
          $claimId: Int!,
          $userId: Int!
        ) {
          unCancelArcClaim (
            userId: $userId,
            claimId: $claimId
          ) {
            id
          }
        }
      `
    })
  }

  claimDateWithinMinutes (date: Date | string, minutes: number): boolean {
    const datePlusXMins = new Date(new Date(date).getTime() + minutes * 60000).getTime()
    return datePlusXMins > new Date().getTime()
  }

  getNextArcToReview (): Promise<ArcDto | null> {
    return this.apolloClientService.query({
      query: gql`
        query GetNextArcToReview {
          getNextArcToReview {
            ...ArcFragment
          }
        }
        ${this.arcFragments.arc}
      `
    }).then((arcDto: any) => {
      return this.getArcResult(arcDto)
    })
  }

  async getClaimEligibility (arc: ArcDto, user: UserDto): Promise<ClaimEligibilityStatusDto> {
    const { ...reasons } = bsConstants.ARCS.CLAIMS.INELIGIBLE_REASONS

    if (user.userEmails?.find(f => f.status === bsConstants.USER.EMAILS.STATUS.VERIFIED) === void 0) {
      return {
        ineligibleReason: reasons.EMAIL_NOT_VERIFIED
      }
    }

    return this.getClaimEligibilityForUser({
      arcId: arc.id,
      suggestArc: true
    })
  }

  async getClaimEligibilityForUser (args: GetClaimEligibilityStatusArgs): Promise<ClaimEligibilityStatusDto> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetClaimEligibilityForUser (
          $arcId: Int!,
          $suggestArc: Boolean!
        ) {
          getClaimEligibilityForUser (
            arcId: $arcId,
            suggestArc: $suggestArc
          ) {
            ineligibleReason,
            reasonDate,
            reasonDescription,
            existingClaim {
              ...ArcClaimFragment
            },
            suggestedArc {
              ...ArcFragment
            },
            suggestedBooks {
              ...BookFragment
            }
          }
        }
        ${this.arcFragments.claim}
        ${this.arcFragments.arc},
        ${this.bookService.bookFragments.book}
      `
    }).then((result: any) => {
      return {
        ...result,
        suggestedArc: this.getArcResult(result.suggestedArc)
      }
    })
  }

  updateDueCutOffDate (arc: ArcDto): Promise<ArcDto> {
    return this.apolloClientService.mutate({
      variables: {
        id: arc.id,
        bookId: arc.bookId
      },
      mutation: gql`
        mutation UpdateDueCutOffDate (
          $id: Int!,
          $bookId: Int!
        ) {
          updateDueCutOffDate (
            id: $id,
            bookId: $bookId
          ) {
            ...ArcFragment
          }
        }
        ${this.arcFragments.arc}
      `
    })
  }

  getAuthorReviewsLinkForSite (site: number, book: BookDto): string {
    const { ...sites } = bsConstants.ARCS.REVIEWS.FLAGS

    const arcSite = this.getArcSitesFromFlags(site)[0]
    const link = (book as HasIndex)[arcSite.fieldName]

    switch (site) {
      case sites.BARNES:
        return link + '#reviews'
      case sites.KOBO:
        return link + '#ratings-and-reviews'
      case sites.GOOGLE:
        return link + '&showAllReviews=true'
      case sites.AUDIBLE_US:
        return link + '#customer-reviews'
      case sites.AUDIBLE_UK:
        return link + '#customer-reviews'
      default:
        return link
    }
  }

  getReviewLinkForSite (
    site: number,
    arc: ArcDto,
    arcClaim?: ArcClaimDto // only needed for Audible
  ): string | undefined {
    if (!arc.book) return

    const { ...sites } = bsConstants.ARCS.REVIEWS.FLAGS
    switch (site) {
      case sites.AMAZON:
        return validStr(arc.book.linkAmazonAsin)
          ? `https://www.amazon.com/gp/product/${arc.book.linkAmazonAsin}`
          : ''
      case sites.BARNES:
      case sites.AUDIO_BARNES: {
        let link = arc.arcType === bsConstants.ARCS.TYPE.EBOOK ? arc.book.linkBarnes : arc.book.linkBarnesAudio
        if (!link) {
          return `https://www.barnesandnoble.com/s/${arc.book.title}`
        }
        // 1. Change the first folder from /w/ to /reviews/
        // 2. Add #reviews to the end

        // 1
        link = link.replace('/w/', '/reviews/').replace('#/', '')
        // 2
        return link + '#reviews'
      }
      case sites.KOBO:
      case sites.AUDIO_KOBO: {
        let link = arc.arcType === bsConstants.ARCS.TYPE.EBOOK ? arc.book.linkKobo : arc.book.linkKoboAudio
        if (!link) {
          return `https://www.kobo.com/us/en/search?query=${arc.book.title}`
        }
        // 1. Add #ratings-and-reviews to the end
        // 2. Remove country and language identifiers. In this case, that's "th/en/" (Thailand/English) just after the URL.
        // Removing this will send the user to Kobo for their own country in their own language.

        // 1
        link = link + '#ratings-and-reviews'

        // 2
        const regex = /www.kobo.com(.*\/)ebook/gi
        const match = regex.exec(link)
        if (match)
          return link.replace(match[1], '/')
        else
          return link
      }
      case sites.ITUNES:
      case sites.AUDIO_ITUNES: {
        let link = arc.arcType === bsConstants.ARCS.TYPE.EBOOK ? arc.book.linkITunes : arc.book.linkITunesAudio
        if (!link) {
          return `https://www.apple.com/us/search/${arc.book.title}`
        }
        // 1. Remove existing query params
        // 2. Add our own affiliate link
        // 3. Send them to the reviews section of the book

        // 1
        link = link.substring(0, link.includes('?') ? link.lastIndexOf('?') : link.length)

        // 2
        link = link + '?at=1001l9EK'

        // 3
        //link = link + '#see-all/reviews'
        return link
      }
      case sites.AUDIBLE_US:
        return arc.book.linkAudibleUs
          ? arc.book.linkAudibleUs + '#customer-reviews'
          : void 0
      case sites.AUDIBLE_UK:
        return arc.book.linkAudibleUk
          ? arc.book.linkAudibleUk + '#customer-reviews'
          : void 0
      case sites.AUDIBLE:
        // special case here
        // users will see only Audible site,
        // but when they claim arc, it will be either US or UK
        // hence why we need to check claim here

        if (arcClaim?.selectedAudioSiteFlag === bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE_US && arc.book.linkAudibleUs) {
          return arc.book.linkAudibleUs + '#customer-reviews'
        } else if (arcClaim?.selectedAudioSiteFlag === bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE_UK && arc.book.linkAudibleUk) {
          return arc.book.linkAudibleUk + '#customer-reviews'
        }

        return void 0
      // These are just returning normal links the author supplies
      case sites.GOOD_READS:
      case sites.AUDIO_GOODREADS: {
        const link = arc.arcType === bsConstants.ARCS.TYPE.EBOOK ? arc.book.linkGoodreads : arc.book.linkGoodreadsAudio
        if (!link) {
          return `https://www.goodreads.com/search?q=${arc.book.title}`
        }

        return link
      }
      case sites.BOOK_BUB:
      case sites.AUDIO_BOOK_BUB: {
        const link = arc.arcType === bsConstants.ARCS.TYPE.EBOOK ? arc.book.linkBookbub : arc.book.linkBookbubAudio
        if (!link) {
          return `https://www.bookbub.com/search?search=${arc.book.title}`
        }

        return link
      }
      case sites.GOOGLE:
      case sites.AUDIO_GOOGLE: {
        const link = arc.arcType === bsConstants.ARCS.TYPE.EBOOK ? arc.book.linkGoogle : arc.book.linkGoogleAudio
        if (!link) {
          return `https://play.google.com/store/search?q=${arc.book.title}&c=books`
        }

        return link
      }
      case sites.SMASH_WORDS:
        if (!arc.book.linkSmashwords) {
          return `https://www.smashwords.com/books/titles/${arc.book.title}`
        }

        return arc.book.linkSmashwords
      default:
        return ''
    }
  }

  getClaimSites (claim: ArcClaimDto): ArcReviewSiteDto[] {
    const result: ArcReviewSiteDto[] = []
    if (!claim?.review?.sites) {
      return result
    }

    const requireLinkSites = [
      bsConstants.ARCS.REVIEWS.FLAGS.AMAZON,
      bsConstants.ARCS.REVIEWS.FLAGS.BOOK_BUB,
      bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_BOOK_BUB,
      bsConstants.ARCS.REVIEWS.FLAGS.GOOD_READS,
      bsConstants.ARCS.REVIEWS.FLAGS.AUDIO_GOODREADS
    ]

    for (const site of claim.review.sites.filter(f => f.status !== bsConstants.ARCS.REVIEWS.SITE.STATUS.NONE || f.exempt)) {
      const thisSite = { ...site }
      // modify review links to include book link
      // for sites which don't require a link (like Google Play)
      if (!requireLinkSites.includes(thisSite.site)) {
        const siteBookKey: string = (bsConstants.ARCS.REVIEWS.FLAG_BOOK_KEYS_SITE as HasIndex)[site.site]
        const authorBookLink = claim?.arc?.book ? (claim.arc.book as HasIndex)[siteBookKey] : ''

        if (authorBookLink) {
          thisSite.link = authorBookLink
        }
      }

      result.push(thisSite)
    }

    return result
  }

  getAllowedExemptSitesForClaim (claim: ArcClaimDto): {
    name: string,
    site: number,
    selected: boolean,
    disabled: boolean
  }[] {
    const sites = []
    // If the reviewer only needs to say yes/no to "prove" their review on a store,
    // then the author should not be able to grant exemptions for that store
    const { SMASH_WORDS, KOBO, BARNES, GOOGLE, ITUNES, AUDIBLE_UK, AUDIBLE_US, AUDIBLE } = bsConstants.ARCS.REVIEWS.FLAGS
    const excludeSites = SMASH_WORDS | KOBO | BARNES | GOOGLE | ITUNES | AUDIBLE_UK | AUDIBLE_US | AUDIBLE

    // Get the sites which have been set against the claim (don't show them all).

    // Only list stores that the author has marked as required, or optionally required.
    // If the store is 100% optional, then don't let authors grant an exemption for it.
    let arcSites = this.getArcSitesFromFlags(claim.reviewFlags, excludeSites)
    const requiredFlagCount = this.countSiteFlags(claim.reviewFlags)
    if (claim.requiredReviewCount || 0 > requiredFlagCount) {
      arcSites = arcSites.concat(this.getArcSitesFromFlags(claim.optionalReviewFlags || 0, excludeSites))
    }

    // Build our data
    for (const arcSite of arcSites) {
      const reviewSite = claim.review?.sites?.find(s => s.site === arcSite.site)
      const hasExemption = !!(reviewSite && reviewSite.exempt)
      const hasReviewedAlready = !!reviewSite && isValidReviewLink(reviewSite.site, reviewSite.link || '')
      // const hasBeenRejected = !!reviewSite && reviewSite.status === bsConstants.ARCS.REVIEWS.SITE.STATUS.REJECTED
      const hasBeenDelayed = !!reviewSite && reviewSite.status === bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED

      sites.push({
        name: arcSite.description,
        site: arcSite.site,
        // Do any of the sites passed in already have review exemptions? Set them here.
        selected: hasExemption,
        disabled: hasReviewedAlready && !hasBeenDelayed
      })
    }

    return sites
  }

  markClaimAsRead (id: number): Promise<any> {
    return this.updateClaimState(id, bsConstants.ARCS.CLAIMS.STATE.READ)
  }

  markClaimAsUnRead (id: number): Promise<any> {
    return this.updateClaimState(id, bsConstants.ARCS.CLAIMS.STATE.NEW)
  }

  markClaimAsNewActivity (id: number): Promise<any> {
    return this.updateClaimState(id, bsConstants.ARCS.CLAIMS.STATE.NEW_ACTIVITY)
  }

  updateClaimState (id: number, state: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        claimId: id,
        state
      },
      mutation: gql`
        mutation UpdateClaimState (
          $claimId: Int!,
          $state: Int!
        ) {
          updateClaimState (
            claimId: $claimId,
            state: $state
          )
        }
      `
    })
  }

  markReviewSiteAsSeen (id: number): Promise<any> {
    return this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation MarkReviewSiteAsSeen (
          $id: Int!
        ) {
          markReviewSiteAsSeen (
            id: $id
          ) {
            id,
            link,
            site,
            status,
            statusDate,
            statusReason,
            exempt,
            state
          }
        }
      `
    })
  }

  getWebStats (): Promise<ArcWebStatsDto> {
    return this.apolloClientService.query({
      query: gql`
        query GetArcWebStats {
          arcWebStats {
            arcReviewCount,
            arcReviewUserCount,
            arcLiveCount,
            authorCount,
            arcActiveCount,
            topArc {
              id,
              totalClaims,
              daysLeft,
              book {
                title,
                bookCover,
                penName,
                authorPenName {
                  name
                }
              }
            }
          }
        }
      `
    })
  }

  getUserStats (): Promise<ArcWebStatsDto> {
    return this.apolloClientService.query({
      query: gql`
        query UserArcStats {
          userArcStats {
            arcReviewCount,
            arcReviewUserCount,
            arcLiveCount,
            authorCount,
            totalArcCount
          }
        }
      `
    })
  }

  arcAvailableDownloadCodeCounts (id: number): Promise<AvailableDownloadCodeDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query ArcAvailableDownloadCodeCounts ( $id: Int!) {
          arcAvailableDownloadCodeCounts (id: $id) {
            site,
            count
          }
        }
      `
    })
  }

  getUsedDownloadCodes (id: number): Promise<UsedDownloadCodeDto[]> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query ArcUsedDownloadCodes ( $id: Int!) {
          arcUsedDownloadCodes (id: $id) {
            code
          }
        }
      `
    })
  }

  getActiveArcDates (args: GetActiveArcDatesArgs): Promise<KvpDto[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetActiveArcDates (
          $excludeArcId: Int
        ) {
          getActiveArcDates (
            excludeArcId: $excludeArcId
          ) {
            key,
            value
          }
        }
      `
    })
  }

  getArcFollowerStats (id: number): Promise<ArcFollowerStatsDto> {
    return this.apolloClientService.query({
      variables: {
        id
      },
      query: gql`
        query GetArcFollowerStats (
          $id: Int!
        ) {
          getArcFollowerStats (
            id: $id
          ) {
            penNameFollowerCount,
            arcTeamFollowerCount,
            arcTeamMemberCount
          }
        }
      `
    })
  }

  getArcTotalOverdueDays (): Promise<number> {
    return this.apolloClientService.query({
      query: gql`
        query GetArcTotalOverdueDays {
          getArcTotalOverdueDays
        }
      `
    })
  }

  async hasAlreadyCounted (id: number, property: string) {
    const existingProps = JSON.parse(LocalStorage.getItem<string>('view_tracker') + '')
    if (!existingProps) {
      return false
    }

    // if the user has already been counted within the last hour, don't count again
    const lastCounted = existingProps[id] && existingProps[id][property] ? existingProps[id][property] : void 0
    if (lastCounted && lastCounted > ((Date.now() / 1000) - 3600)) {
      return true
    }

    return false
  }

  async trackUserCounted (id: number, property: string) {
    let existingProps = JSON.parse(LocalStorage.getItem<string>('view_tracker') + '')
    if (!existingProps) {
      existingProps = {
        [id]: {
          [property]: Date.now() / 1000
        }
      }
    } else if (!existingProps[id]) {
      existingProps[id] = {
        [property]: Date.now() / 1000
      }
    } else {
      existingProps[id][property] = Date.now() / 1000
    }

    LocalStorage.set('view_tracker', JSON.stringify(existingProps))
  }

  async addViewToArc (id: number): Promise<any> {
    /*if (await this.hasAlreadyCounted(id, 'vc')) {
      return
    }*/

    await this.apolloClientService.mutate({
      variables: {
        id
      },
      mutation: gql`
        mutation AddViewToArc (
          $id: Int!
        ) {
          addViewToArc(id: $id) {
            id
          }
        }
      `
    })
    // await this.trackUserCounted(id, 'vc')
  }

  async addImpressionToArc (id: number): Promise<any> {
    /*if (await this.hasAlreadyCounted(id, 'ic')) {
      return
    }*/

    await this.apolloClientService.mutate({
      variables: {

        id
      },
      mutation: gql`
        mutation AddImpressionToArc (
          $id: Int!
        ) {
          addImpressionToArc(id: $id) {
            id
          }
        }
      `
    })
    // await this.trackUserCounted(id, 'ic')
  }

  getDuplicateRunningOrScheduledArcs (args: {
    bookId: number,
    arcType: number,
    currentArcId: number
  }): Promise<any[]> {
    return this.apolloClientService.query({
      variables: {
        ...args
      },
      query: gql`
        query GetDuplicateRunningOrScheduledArcs (
          $bookId: Float
          $arcType: Float
          $currentArcId: Float
        ) {
          getDuplicateRunningOrScheduledArcs (
            bookId: $bookId,
            arcType: $arcType,
            currentArcId: $currentArcId
          ) {
            id,
            bookId,
            arcType,
            liveDate,
            stopDownloadDate
          }
        }
      `
    })
  }

  fullUrlFromShortUrl (url: string): Promise<any> {
    return this.axiosService.axios.post('arc/full-url-from-short-url', { url }).then((r: AxiosResponse) => {
      return r.data
    })
  }
}
