// Utils
import { Component, Prop } from 'vue-property-decorator'
import { CreateElement } from 'vue/types/vue'

// Mixins
import CommonMixin from '../mixins/common'
import { QBtn, QIcon } from 'quasar'
import { ArcReviewSiteDto, BookDto, bsConstants, HasIndex, validStr } from 'booksprout'

@Component
export default class BsSiteBtn extends CommonMixin {
  @Prop({ type: Number }) readonly site!: number
  @Prop({ type: Object }) readonly reviewSite!: ArcReviewSiteDto
  @Prop({ type: Object }) readonly book!: BookDto

  @Prop({ type: Boolean }) readonly normal!: boolean
  @Prop({ type: Boolean }) readonly delayed!: boolean
  @Prop({ type: Boolean }) readonly rejected!: boolean
  @Prop({ type: Boolean }) readonly exempt!: boolean
  @Prop({ type: Boolean }) readonly userOwnsArc!: boolean

  @Prop({ type: Boolean }) readonly newReviewOverride!: boolean

  get isRejected () {
    return !this.isExempt && (this.rejected || (this.reviewSite !== void 0 && this.reviewSite.status === bsConstants.ARCS.REVIEWS.SITE.STATUS.REJECTED))
  }

  get isDelayed () {
    return !this.isExempt && (this.delayed || (this.reviewSite !== void 0 && this.reviewSite.status === bsConstants.ARCS.REVIEWS.SITE.STATUS.DELAYED))
  }

  get isExempt () {
    return this.exempt || (this.reviewSite !== void 0 && this.reviewSite.exempt)
  }

  get isNormal () {
    return this.normal || (!this.isDelayed && !this.isRejected && !this.isExempt)
  }

  get getIcon () {
    const name = (bsConstants.ARCS.REVIEWS.FLAG_ICONS as HasIndex)[this.site]
    return `app:${name}`
  }

  get needsSiteLink () {
    const siteBookKey = (bsConstants.ARCS.REVIEWS.FLAG_BOOK_KEYS_SITE as HasIndex)[this.site]

    if (this.site === bsConstants.ARCS.REVIEWS.FLAGS.AMAZON) {
      return this.book &&
        !validStr((this.book as HasIndex)[siteBookKey]) &&
        !validStr(this.book.linkAmazonAsinPrint) &&
        this.userOwnsArc
    }

    if (this.site === bsConstants.ARCS.REVIEWS.FLAGS.AUDIBLE) {
      return this.book
        && !validStr(this.book.linkAudibleUs)
        && !validStr(this.book.linkAudibleUk)
    }

    return this.book && !validStr((this.book as HasIndex)[siteBookKey]) && this.userOwnsArc
  }

  get newReview () {
    return this.userOwnsArc && (this.newReviewOverride || this.reviewSite?.state === bsConstants.ARCS.REVIEWS.SITE.STATE.NEW_LINK || false)
  }

  get isIncomplete () {
    return this.reviewSite === void 0 || this.needsSiteLink
  }

  get shouldRenderLink () {
    return this.$attrs.href && !this.isRejected && !this.isDelayed && !this.isExempt
  }

  getHasReviewAvailable () {
    return !!this.reviewSite.review
  }

  __renderIcon (h: CreateElement) {
    return h(QIcon, {
      staticClass: 'font-size-20',
      props: {
        name: this.getIcon
      }
    })
  }

  __renderText (h: CreateElement) {
    if (this.isNormal) {
      return void 0
    }

    const text = this.isDelayed
      ? 'Delayed'
      : (this.isExempt ? 'Exempt' : 'Rejected')

    return h('span', {
      staticClass: 'on-right bs-tag-s'
    }, text)
  }

  __renderBadge (h: CreateElement) {
    return h('span', {
      staticClass: 'bs-sb-badge',
      class: {
        'bs-sb-badge--new': this.newReview,
        'bs-sb-badge--slr': this.needsSiteLink
      }
    })
  }

  __emitClick (e: Event) {
    if (this.isIncomplete && !this.needsSiteLink) {
      return
    }

    if (this.isRejected && !this.isNormal) {
      this.$emit('rejected-click', this.reviewSite)
    } else if (this.isDelayed && !this.isNormal) {
      this.$emit('delayed-click', this.reviewSite)
    } else if (this.isExempt && !this.isNormal) {
      this.$emit('exempt-click', this.reviewSite)
    } else if (this.needsSiteLink) {
      // don't open the search link
      e.preventDefault()
      this.$emit('add-site-link', e)
    } else {
      this.$emit('open-site-link', e)
    }
  }

  render (h: CreateElement) {
    const dataCy = `bsSiteBtn_${(bsConstants.ARCS.REVIEWS.FLAG_DESCRIPTIONS as HasIndex)[this.site].toLowerCase()}`

    return h(this.shouldRenderLink ? 'a' : QBtn, {
      staticClass: 'bs-site-btn border-radius flex q-pa-sm items-center relative-position',
      class: this.getClasses({
        'bs-site-btn__rejected': this.isRejected && !this.isNormal,
        'bs-site-btn__delayed': this.isDelayed && !this.isNormal,
        'bs-site-btn__exempt': this.isExempt && !this.isNormal,
        // This will show the faded view for link required and no reviews available.
        'bs-site-btn__incomplete cursor-not-allowed': this.isIncomplete && !this.needsSiteLink,
        'cursor-inherit': !this.isNormal
      }),
      props: {
        flat: true
      },
      on: {
        click: this.__emitClick
      },
      attrs: {
        ['data-cy']: dataCy,
        target: this.$attrs.href ? '_blank' : void 0,
        rel: this.$attrs.href ? 'noopener noreferrer': void 0
      }
    }, [
      this.__renderBadge(h),
      this.__renderIcon(h),
      this.__renderText(h),
      this.slot(this, 'default')
    ])
  }
}
